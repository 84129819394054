var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%" } },
    [
      _c(
        "v-row",
        { staticStyle: { height: "100%" } },
        [
          _c(
            "v-col",
            { staticClass: "pa-0", staticStyle: { height: "100%" } },
            [
              _c("v-treeview", {
                staticStyle: { padding: "30px" },
                attrs: {
                  id: "tutti-file-storage-treeview",
                  "open-on-click": "",
                  activatable: "",
                  "return-object": "",
                  active: _vm.activeItem,
                  open: _vm.openDirs,
                  items: _vm.files,
                  "item-key": "path",
                  "load-children": _vm.loadChildren,
                },
                on: {
                  "update:active": function ($event) {
                    _vm.activeItem = $event
                  },
                  "update:open": function ($event) {
                    _vm.openDirs = $event
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "prepend",
                      fn: function ({ item, open }) {
                        return [
                          item.type === "dir"
                            ? _c("v-icon", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      open ? "mdi-folder-open" : "mdi-folder"
                                    ) +
                                    " "
                                ),
                              ])
                            : _c("v-icon", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getIconStringForFile(item.name)
                                    ) +
                                    " "
                                ),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "label",
                      fn: function ({ item, active }) {
                        return [
                          _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.onNodeClick(item, active)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "mr-4" }, [
                                _vm._v(
                                  _vm._s(item.name) +
                                    _vm._s(item.type === "dir" ? "/" : "")
                                ),
                              ]),
                              item.type === "dir"
                                ? _c(
                                    "v-menu",
                                    {
                                      attrs: { "offset-y": "" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ attrs, on }) {
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          icon: "",
                                                          small: "",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { attrs: { small: "" } },
                                                      [
                                                        _vm._v(
                                                          "mdi-dots-vertical"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _c(
                                        "v-list",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadChildren(
                                                    item,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        left: "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-refresh")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "console.fileExplorer.menu.refresh"
                                                      )
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDialog(
                                                    "createFile",
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        left: "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-file-plus")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "console.fileExplorer.menu.newFile"
                                                      )
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDialog(
                                                    "createFolder",
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        left: "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-folder-plus")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "console.fileExplorer.menu.newFolder"
                                                      )
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          item.name !== ""
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openDialog(
                                                        "renameFile",
                                                        item,
                                                        item.name
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            left: "",
                                                          },
                                                        },
                                                        [_vm._v("mdi-pencil")]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "console.fileExplorer.menu.rename"
                                                          )
                                                        )
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          item.name !== ""
                                            ? _c(
                                                "v-list-item",
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openDeleteFileDialog(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-list-item-title",
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            left: "",
                                                          },
                                                        },
                                                        [_vm._v("mdi-delete")]
                                                      ),
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "console.fileExplorer.menu.delete"
                                                          )
                                                        )
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openUploadDialog(
                                                    "file",
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        left: "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-upload")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "console.fileExplorer.menu.uploadFiles"
                                                      )
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-list-item",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openUploadDialog(
                                                    "folder",
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "v-list-item-title",
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      attrs: {
                                                        small: "",
                                                        left: "",
                                                      },
                                                    },
                                                    [_vm._v("mdi-upload")]
                                                  ),
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "console.fileExplorer.menu.uploadFolder"
                                                      )
                                                    )
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._t("label-append", null, {
                                active: active,
                                item: item,
                                renameFile: (item, name) => {
                                  _vm.openDialog("renameFile", item, name)
                                },
                                deleteFile: _vm.openDeleteFileDialog,
                              }),
                            ],
                            2
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _vm.hasPreviewSlot
            ? _c(
                "v-col",
                { staticClass: "d-flex pa-0" },
                [
                  _c("v-divider", { attrs: { vertical: "" } }),
                  _c(
                    "div",
                    { attrs: { id: "file-preview" } },
                    [
                      _vm.activeItem.length > 0
                        ? [
                            _vm._t("preview", null, {
                              activeItem: _vm.activeItem[0],
                              renameFile: (item, name) => {
                                _vm.openDialog("renameFile", item, name)
                              },
                              deleteFile: _vm.openDeleteFileDialog,
                            }),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("input", {
        ref: "uploadFilesForm",
        staticStyle: { display: "none" },
        attrs: { multiple: "", type: "file" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return (() => {}).apply(null, arguments)
          },
          change: (e) => {
            _vm.onSelectUploadedFiles("file", e)
          },
        },
      }),
      _c("input", {
        ref: "uploadFolderForm",
        staticStyle: { display: "none" },
        attrs: { webkitdirectory: "", type: "file" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return (() => {}).apply(null, arguments)
          },
          change: (e) => {
            _vm.onSelectUploadedFiles("dir", e)
          },
        },
      }),
      _c("tutti-dialog-alert", {
        ref: "dialog",
        on: {
          confirm: function ($event) {
            return _vm.onDialogConfirm(_vm.activeDialogItem.path, _vm.nameInput)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [_vm._v(" " + _vm._s(_vm.dialogTitle) + " ")]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-card-text",
                  [
                    _c("v-text-field", {
                      attrs: {
                        label: _vm.dialogInputLabel,
                        rules: _vm.dialogInputRules,
                      },
                      model: {
                        value: _vm.nameInput,
                        callback: function ($$v) {
                          _vm.nameInput = $$v
                        },
                        expression: "nameInput",
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog-alert", {
        ref: "dialogDeleteFile",
        on: {
          confirm: function ($event) {
            return _vm.deleteFile(_vm.activeDialogItem.path)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("v-icon", { attrs: { color: "error", left: "" } }, [
                  _vm._v("mdi-alert"),
                ]),
                _vm._v(
                  _vm._s(
                    _vm.$t("console.fileExplorer.dialogs.deletePrompt.title", {
                      name: _vm.activeDialogItem.name,
                    })
                  ) + " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("v-card-text", {
                  domProps: {
                    innerHTML: _vm._s(
                      _vm.$t("console.fileExplorer.dialogs.deletePrompt.text", {
                        name: _vm.activeDialogItem.name,
                      })
                    ),
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-dialog-alert", {
        ref: "dialogUpload",
        on: { confirm: _vm.uploadFiles, cancel: _vm.onCancelUpload },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _c("v-icon", { attrs: { color: "warning", left: "" } }, [
                  _vm._v("mdi-alert"),
                ]),
                _vm._v(
                  _vm._s(
                    _vm.$t("console.fileExplorer.dialogs.uploadPrompt.title")
                  ) + " "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c(
                  "v-card-text",
                  {
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.$t("console.fileExplorer.dialogs.uploadPrompt.text")
                      ),
                    },
                  },
                  [
                    _c("textarea", {
                      attrs: {
                        readonly: "",
                        id: "textarea-existing-file-paths",
                      },
                      domProps: {
                        innerHTML: _vm._s(_vm.existingFilePaths.join("\n")),
                      },
                    }),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("tutti-snackbar", { ref: "snackbar" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }