<template>
    <div style="height: 100%;">
        <v-row style="height: 100%;">
            <v-col class="pa-0" style="height: 100%">
                <v-treeview
                    id="tutti-file-storage-treeview"
                    open-on-click
                    activatable
                    return-object
                    :active.sync="activeItem"
                    :open.sync="openDirs"
                    :items="files"
                    item-key="path"
                    :load-children="loadChildren"
                    style="padding: 30px;"
                >
                    <template #prepend="{ item, open }">
                        <v-icon v-if="item.type==='dir'">
                            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                        </v-icon>
                        <v-icon v-else>
                            {{ getIconStringForFile(item.name) }}
                        </v-icon>
                    </template>
                    <template #label="{ item, active }">
                        <div @click="onNodeClick(item, active)">
                            <span class="mr-4">{{ item.name }}{{ item.type === 'dir' ? '/' : '' }}</span>
                            <v-menu
                                v-if="item.type === 'dir'"
                                offset-y
                            >
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon small>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item @click="loadChildren(item, true)">
                                        <v-list-item-title><v-icon small left>mdi-refresh</v-icon>{{ $t('console.fileExplorer.menu.refresh') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openDialog('createFile', item)">
                                        <v-list-item-title><v-icon small left>mdi-file-plus</v-icon>{{ $t('console.fileExplorer.menu.newFile') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openDialog('createFolder', item)">
                                        <v-list-item-title><v-icon small left>mdi-folder-plus</v-icon>{{ $t('console.fileExplorer.menu.newFolder') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.name!==''" @click="openDialog('renameFile', item, item.name)">
                                        <v-list-item-title><v-icon small left>mdi-pencil</v-icon>{{ $t('console.fileExplorer.menu.rename') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item v-if="item.name!==''" @click="openDeleteFileDialog(item)">
                                        <v-list-item-title><v-icon small left>mdi-delete</v-icon>{{ $t('console.fileExplorer.menu.delete') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openUploadDialog('file', item)">
                                        <v-list-item-title><v-icon small left>mdi-upload</v-icon>{{ $t('console.fileExplorer.menu.uploadFiles') }}</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="openUploadDialog('folder', item)">
                                        <v-list-item-title><v-icon small left>mdi-upload</v-icon>{{ $t('console.fileExplorer.menu.uploadFolder') }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <slot
                                name="label-append"
                                :active="active"
                                :item="item"
                                :rename-file="(item, name) => { openDialog('renameFile', item, name) }"
                                :delete-file="openDeleteFileDialog"
                            >
                            </slot>
                        </div>
                    </template>
                </v-treeview>
            </v-col>
            <v-col v-if="hasPreviewSlot" class="d-flex pa-0">
                <v-divider vertical></v-divider>
                <div id="file-preview">
                    <template v-if="activeItem.length > 0">
                        <slot
                            name="preview"
                            :active-item="activeItem[0]"
                            :rename-file="(item, name) => { openDialog('renameFile', item, name) }"
                            :delete-file="openDeleteFileDialog"
                        ></slot>
                    </template>
                </div>
            </v-col>
        </v-row>

        <input
            multiple
            ref="uploadFilesForm"
            type="file"
            style="display:none"
            @click.stop="() => {}"
            @change="(e) => { onSelectUploadedFiles('file', e) }"
        />
        <input
            webkitdirectory
            ref="uploadFolderForm"
            type="file"
            style="display:none"
            @click.stop="() => {}"
            @change="(e) => { onSelectUploadedFiles('dir', e) }"
        />

        <tutti-dialog-alert
            ref="dialog"
            @confirm="onDialogConfirm(activeDialogItem.path, nameInput)"
        >
            <template #title>
                {{ dialogTitle }}
            </template>
            <template #body>
                <v-card-text>
                    <v-text-field v-model="nameInput" :label="dialogInputLabel" :rules="dialogInputRules"></v-text-field>
                </v-card-text>
            </template>
        </tutti-dialog-alert>

        <tutti-dialog-alert
            ref="dialogDeleteFile"
            @confirm="deleteFile(activeDialogItem.path)"
        >
            <template #title>
                <v-icon color="error" left>mdi-alert</v-icon>{{ $t('console.fileExplorer.dialogs.deletePrompt.title', { name: activeDialogItem.name }) }}
            </template>
            <template #body>
                <v-card-text v-html="$t('console.fileExplorer.dialogs.deletePrompt.text', { name: activeDialogItem.name })">
                </v-card-text>
            </template>
        </tutti-dialog-alert>

        <tutti-dialog-alert
            ref="dialogUpload"
            @confirm="uploadFiles"
            @cancel="onCancelUpload"
        >
            <template #title>
                <v-icon color="warning" left>mdi-alert</v-icon>{{ $t('console.fileExplorer.dialogs.uploadPrompt.title') }}
            </template>
            <template #body>
                <v-card-text v-html="$t('console.fileExplorer.dialogs.uploadPrompt.text')">
                    <textarea
                        readonly
                        id="textarea-existing-file-paths"
                        v-html="existingFilePaths.join('\n')"
                    ></textarea>
                </v-card-text>
            </template>
        </tutti-dialog-alert>

        <tutti-snackbar ref="snackbar"></tutti-snackbar>
    </div>
</template>
<script>
import TuttiDialogAlert from './TuttiDialogAlert'
import rules from '@/lib/input-rules'
import TuttiSnackbar from './TuttiSnackbar.vue';
import { getFileExtension } from '@/lib/utils'

const icons = {
    vue: 'mdi-vuejs',
    html: 'mdi-language-html5',
    js: 'mdi-nodejs',
    json: 'mdi-code-json',
    md: 'mdi-language-markdown',
    pdf: 'mdi-file-pdf',
    png: 'mdi-file-image-outline',
    jpg: 'mdi-file-image-outline',
    jpeg: 'mdi-file-image-outline',
    gif: 'mdi-file-image-outline',
    ico: 'mdi-file-image-outline',
    bmp: 'mdi-file-image-outline',
    txt: 'mdi-file-document-outline',
    xls: 'mdi-file-excel',
    mp3: 'mdi-file-music-outline',
    wav: 'mdi-file-music-outline',
    m4a: 'mdi-file-music-outline',
    mp4: 'mdi-file-video-outline',
    webm: 'mdi-file-video-outline',
};

export default {
    components: { TuttiDialogAlert, TuttiSnackbar },
    data() {
        return {
            icons,
            dialogInfo: {
                createFolder: {
                    title: this.$t('console.fileExplorer.dialogs.createFolder.title'),
                    inputLabel: this.$t('console.fileExplorer.dialogs.createFolder.inputLabel'),
                    inputRules: [rules.required]
                },
                createFile: {
                    title: this.$t('console.fileExplorer.dialogs.createFile.title'),
                    inputLabel: this.$t('console.fileExplorer.dialogs.createFile.inputLabel'),
                    inputRules: [rules.required]
                },
                renameFile: {
                    title: this.$t('console.fileExplorer.dialogs.renameFile.title'),
                    inputLabel: this.$t('console.fileExplorer.dialogs.renameFile.inputLabel'),
                    inputRules: [rules.required]
                },
            },

            activeItem: [],
            files: [],
            filesMap: {},
            openDirs: [],
            activeDialogItem: {},
            nameInput: '',
            existingFilePaths: [],
            filesToUpload: null,
            uploadProgress: 0,
        
            onDialogConfirm: () => {},

            dialogTitle: '',
            dialogInputLabel: '',
            dialogInputRules: [],
        }
    },
    props: {
        client: { default: null },
        source: { type: String },
        rootPath: { type: String, default: '' }
    },
    computed: {
        hasPreviewSlot() {
            return !!this.$scopedSlots.preview;
        }
    },
    methods: {
        onNodeClick(item, active) {
            if(active) this.$emit('open', item.path);
        },
        async loadChildren(file = null, refresh = false) {
            if(!refresh && file && this.filesMap[file.path].children.length > 0) return null;
            else {
                let files = await this.client._duct.call(
                    this.client._duct.EVENT.SYSTEM_FS_LIST_FILES_FOR_PATH,
                    { path: file.path, source: this.source }
                );
                files.forEach(f => {
                    if(f.type === 'dir') f.children = [];
                    this.filesMap[f.path] = f;
                });
                if(file) {
                    this.filesMap[file.path].children = files.map(f => this.filesMap[f.path]);
                }
                return files;
            }
        },
        getIconStringForFile(name) {
            const ext = getFileExtension(name);
            if(ext === null) return 'mdi-file-outline';
            return this.icons[ext] || 'mdi-file-outline';
        },
        openDialog(action, item, defaultInput = '') {
            this.onDialogConfirm = this[action];
            this.dialogTitle = this.dialogInfo[action].title;
            this.dialogInputLabel = this.dialogInfo[action].inputLabel;
            this.dialogInputRules = this.dialogInfo[action].inputRules;
            this.activeDialogItem = item;
            this.nameInput = defaultInput;
            this.$refs.dialog.show();
        },
        openDeleteFileDialog(item) {
            this.activeDialogItem = item;
            this.$refs.dialogDeleteFile.show();
        },
        async createFolder(path, name) {
            const { success, content } = await this.client._duct.call(
                this.client._duct.EVENT.SYSTEM_FS_CREATE_FOLDER,
                { path, name, source: this.source }
            );
            if(success) {
                const newPath = path !== '' ? `${path}/${name}` : name;
                this.filesMap[newPath] = {
                    name,
                    path: newPath,
                    type: 'dir',
                    children: [],
                };
                this.filesMap[path].children.push(this.filesMap[newPath]);
                this.filesMap[path].children.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                this.$refs.snackbar.show('success', this.$t('console.fileExplorer.snackbar.createFolderSuccess', { name }));
            } else {
                if(content.error_code===this.client.ERROR.SYSTEM_FS.EXST_FOLDER){
                    this.$refs.snackbar.show('error', this.$t('console.fileExplorer.snackbar.createFolderFailure', { name }));
                } else {
                    alert(`Unknown error: ${JSON.stringify(content.stacktrace)}`)
                }
            }
        },
        async renameFile(currentPath, name) {
            const parentPath = currentPath.split('/').slice(0, -1).join('/');
            const newPath = [ ...parentPath.split('/'), name ].join('/');
            const { success, content } = await this.client._duct.call(
                this.client._duct.EVENT.SYSTEM_FS_RENAME_FILE,
                { current_path: currentPath, new_path: newPath, source: this.source }
            );
            if(success) {
                this.filesMap[newPath] = {
                    name,
                    path: newPath,
                    type: this.filesMap[currentPath].type,
                    children: this.filesMap[currentPath].type === 'dir' ? [] : undefined,
                }
                delete this.filesMap[currentPath];
                for(let i in this.filesMap[parentPath].children) {
                    if(this.filesMap[parentPath].children[i].path === currentPath) {
                        this.filesMap[parentPath].children.splice(i, 1, this.filesMap[newPath]);
                        break;
                    }
                }
                this.activeItem = [];
                this.$refs.snackbar.show('success', this.$t('console.fileExplorer.snackbar.renameFileSuccess', { name }));
            } else {
                if(content.error_code===this.client.ERROR.SYSTEM_FS.EXST_FILE){
                    this.$refs.snackbar.show('error', this.$t('console.fileExplorer.snackbar.renameFileFailure'));
                } else {
                    alert(`Unknown error: ${JSON.stringify(content.stacktrace)}`);
                }
            }
        },
        async deleteFile(path) {
            const { success, content } = await this.client._duct.call(
                this.client._duct.EVENT.SYSTEM_FS_DELETE_FILE,
                { path, source: this.source }
            );
            if(success) {
                const parentPath = path.split('/').slice(0, -1).join('/');
                delete this.filesMap[path];
                const childIndex = this.filesMap[parentPath].children.findIndex(c => c.path === path);
                this.filesMap[parentPath].children.splice(childIndex, 1);
                this.activeItem = [];
                this.$refs.snackbar.show('success', this.$t('console.fileExplorer.snackbar.deleteFileSuccessful'));
            } else {
                alert(`Unknown error: ${JSON.stringify(content)}`)
            }
        },
        openUploadDialog(type, item) {
            this.activeDialogItem = item;
            if(type === 'file') { this.$refs.uploadFilesForm.click(); }
            else if(type === 'folder') { this.$refs.uploadFolderForm.click(); }
        },
        async onSelectUploadedFiles(type, e) {
            Promise.all(Array.from(e.target.files).map( (f) => new Promise(
                (resolve, reject) => {
                    if(!f) reject();
                    let fr = new FileReader();
                    fr.onload = () => {
                        resolve({
                            path: f.webkitRelativePath !== '' ? f.webkitRelativePath : f.name,
                            data: fr.result,
                        });
                    }
                    fr.onerror = reject;
                    fr.readAsArrayBuffer(f);
                }
            ) )).then(async (files) => {
                const filePaths = type === 'dir' ? [files[0].path.split('/')[0]] : files.map((f) => f.path);
                const { success, content: existingFilePaths } = await this.client._duct.call(
                        this.client._duct.EVENT.SYSTEM_FS_EXIST_FILES,
                        {
                            file_paths: filePaths,
                            current_path: this.activeDialogItem.path,
                            source: this.source
                        }
                    );
                if(success) {
                    this.filesToUpload = files;
                    if(existingFilePaths.length > 0) {
                        this.existingFilePaths = existingFilePaths;
                        this.$refs.dialogUpload.show();
                    } else {
                        this.uploadFiles();
                    }
                }
            }).catch((err) => {
                console.error(err);
            }).finally(() => {
                e.target.value = '';
            });
        },
        async createFile(path, name) {
            await this.uploadFiles(path, [{ path: name, data: '' }]);
        },
        async uploadFiles(currentPath, files) {
            const filesToUpload = files || this.filesToUpload;
            let saveFiles;
            if(this.source === 'static') {
                saveFiles = this.client.resource.saveFilesToStorage;
            } else if(this.source === 'projects') {
                saveFiles = this.client.resource.saveFilesToProject;
            }

            try {
                await saveFiles({
                    current_path: currentPath || this.activeDialogItem.path,
                    files: filesToUpload,
                })
                this.$refs.snackbar.show('success', this.$t('console.fileExplorer.snackbar.uploadFilesSuccessful'), 3000);
                this.loadChildren(this.activeDialogItem, true);
            } catch (e) {
                console.log(e);
                alert(`File upload failed: ${JSON.stringify(e.details.stacktrace)}`)
                this.$refs.snackbar.hide();
            }
            this.filesToUpload = null;
        },
        onCancelUpload() {
            this.filesToUpload = null;
        }
    },
    mounted() {
        this.client.invokeOnOpen(async () => {
            let parentFile = {
                name: '',
                path: this.rootPath,
                type: 'dir',
                children: []
            };
            this.filesMap[this.rootPath] = parentFile;
            await this.loadChildren(parentFile)
            this.files = [this.filesMap[this.rootPath]];
            this.openDirs = [this.filesMap[this.rootPath]];
        });
    }
}
</script>
<style>
#tutti-file-storage-treeview {
    height: 100%;
    overflow-y: scroll;
}
#tutti-file-storage-treeview .v-treeview-node__root {
    min-height: 32px;
}
#file-preview {
    max-height: 400px;
    padding: 30px;
    width: 100%;
}
#preview-img {
    max-width: 90%;
    max-height: 100%;
    margin: 20px auto;
    display: block;
}
#preview-audio {
    max-width: 90%;
    margin: 20px auto;
    display: block;
}
#preview-video {
    max-width: 90%;
    margin: 20px auto;
    display: block;
}
#textarea-existing-file-paths {
    width: 100%;
    margin: 20px 0;
    border: 1px solid #ccc;
    height: 100px;
}
</style>